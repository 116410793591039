import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

// Domain imports.
import {
  Button,
  CompanyLogo,
  ConfirmationDialog,
  Container,
  FAQ,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  ImageViewerGuided,
  ImageViewerContainer,
  Legend,
  Loader,
  Main,
  NavBar,
  Paragraph,
  ProgressionIndicator,
  Text,
  UseIcon,
  ValidationFeedback,
} from '@domain/components';
import { goToNextPage } from '@domain/helpers';
import { HTTP_ERROR_ENCOUNTERED } from '@domain/action-types';
import { useFaq, useEnterKey, useFilesEndpoint, useScaling } from '@domain/hooks';
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import { Colors } from '@domain/theming';
import { Box, Stack } from '@mui/material';
import InputFileNoStyle from '@domain/components/InputFileNoStyle';
import Stepper from '@domain/components/MobileStepper';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .uploads__validation {
    min-height: 50px;
    margin-top: 0;
  }
  .image-uploads__title {
    flex: 1;
  }
  .instruction__title {
  text-align: center;
  }
  .image_uploads-feedback-comment {
    margin-bottom: 30px;
    margin-top: 15px;
    .h4 {
      color: ${Colors.RED}
    }
    .feedback-comment {
      margin-top: 0;  
      color: ${Colors.RED}
    }
  }
  .small__image {
    cursor: pointer;
    max-width: ${props => props.$scaling.scaleUpTo4K(250)}px;
    margin-top: ${props => props.$scaling.scaleUpTo4K(10)}px;
    height: auto;
    max-height: 180px; // IE bug -- big images brake from flex flow
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .screen-desktop & {
    .grid-container__main {
      justify-content: space-between;
    }
    .grid__column--main {
      flex: 0 1 50%;
      padding-right: ${props => props.$scaling.scaleUpTo4K(80)}px;
      padding-top: ${props => props.$scaling.scaleUpTo4K(30)}px;
       @media screen and (max-width: 1028px) and (min-width: 581px) {
                align-items:center;
                padding-right: ${props => props.$scaling.scaleUpTo4K(20)}px;
                padding-left: ${props => props.$scaling.scaleUpTo4K(20)}px;
              }
    }
    .grid__column--footer {
      align-items: stretch;
      max-width: ${props => props.$scaling.scaleUpTo4K(500)}px;
      padding-top: ${props => props.$scaling.scaleUpTo4K(40)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(50)}px;
       @media screen and (max-width: 1028px) and (min-width: 581px) {
                padding-left:0;
              }
      .image-upload__uploader-container {
          width: 100%;
          margin: 0 auto;
        }
    }
    .instructions--list {
      min-height: ${props => props.$scaling.scaleUpTo4K(300)}px;
    }
    .image-upload__button-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
    }
    .image-upload__title {
      // margin-top: 100px;
      // max-width: ${props => props.$scaling.scaleUpTo4K(480)}px;
      margin-bottom: auto;
    }
    .uploads__validation {
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
      padding-top: ${props => props.$scaling.scaleUpTo4K(10)}px;
      min-height: ${props => props.$scaling.scaleUpTo4K(50)}px;
    }
  }

  @media screen and (max-width: 1028px) {
    .grid__column--footer {
    padding: 0 0 ${props => props.$scaling.scaleUpTo4K(40)}px;
    }
    .screen-desktop & {
      .grid-container__main {
        padding-top: 30px;
      }
      .image-upload__title {
        margin: 0 auto;
      }
      .instructions--list {
        text-align: left;
        // max-width: 380px;
        margin: 60px auto 0;
        min-height: 320px;
      }
      .grid__column--main {
        padding-right: 0;
         padding-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
      }
      .grid__column--footer {
        flex-direction: column;
        padding-top: 0;
        padding-left: 0;
        padding-bottom:'20px
        .image-upload__uploader-container {
          max-width: 490px;
          width: 100%;
          margin: 0 auto;
        }
        .image-upload__button-container {
          max-width: 500px;
          width: 100%;
          margin: 0 auto;
          align-items: center;
        }
      }
    }
    .mobile {
        display: flex;
        flex-direction: column;
        align-items: center; 
        text-align: center;
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .instructions--list {
        min-height: 250px;
        max-width: none;
        width: 100%;
      }
      .grid__column--footer {
        .image-upload__button-container {
          flex-direction: row;
          justify-content: flex-end;
            max-width: 100%;
        }
      }
    }
     .mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start; 
          text-align: left;
      }
  }
`;

const StyledPlusIcon = styled(UseIcon)`
  opacity: 0.5;
  color: ${Colors.GREY_DARKER};
`;

const StyledValidationFeedback = styled(ValidationFeedback)`
  display: block;
  text-align: center;
  .screen-desktop .grid__column--footer &.validation-feedback {
    width: auto;
  }
`;


function GuidedImagesUploadPage({
  instructions,
  legend,
  heading,
  filesSetName,
  currentPath,
  questionnaire,
  filesSet,
  feedbackCause,
  nextPage,
  prevPage,
  saveIncident,
  validationSchema,
  firstParagraph,
  secondParagraph,
  mobileParagraph,
  faqs,
  faqsMobile,
  isFeedback,
  files,
  incident,
  insurer,
  isPageBeforeSuccess,
  imagesDisplayed,
  showDesktopParagraphOnMobile = false,
  dialogMobile,
  customHelpLink,
  helpLinkText,
  customMiddleLogo = false,
  middleLogo,
  maxImgSize = 10,
  maxNumberOfImages = 30,
  extraDamageImage = false
}) {
  const scaling = useScaling();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();
  const navigate = useNavigate()
  const {
    uploadImages,
    deleteFile,
    numOfUploads,
    setSelectedImage,
    largeFileUpload,
    nonImageUpload,
  } = useFilesEndpoint(filesSetName);

  const dispatch = useDispatch()
  const selectedImage = useSelector(state => state.selectedImage);

  const [errors, setErrors] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [bigFileUpload, setBigImageUpload] = useState(false);
  const [feedbackValidated, setFeedbackValidated] = useState(incident.feedbackValidated || false);
  const [maxNumberOfImagesDialogOpen, setMaxNumberOfImagesDialogOpen] = useState(false);
  const [loadingImg, setLoadingImg] = useState(null)

  function addUploads(event, imageCategory) {

    event.preventDefault();
    const files = event.target.files
    if (files.length > maxNumberOfImages) {
      setMaxNumberOfImagesDialogOpen(true)
      return
    }
    setLoadingImg(imageCategory)
    uploadImages(files, maxImgSize, extraDamageImage, imageCategory);
  }

  const nextButtonEnabled = !errors ? (isFeedback ? feedbackValidated : true) : false;

  useEffect(() => {
    async function checkForErrors() {
      if (isFeedback) {
        try {
          setErrors(null);
        } catch ({ errors }) {
          setErrors(errors);
        } finally {
          if (!feedbackValidated) {
            setErrors(err => err ? ['Upload tenminste 1 nieuwe foto om verder te gaan', ...err] : ['Upload tenminste 1 nieuwe foto om verder te gaan']);
          }
        }

      } else {
        try {
          await validationSchema.validate(filesSet, { abortEarly: true });
          setErrors(null);
        } catch ({ errors }) {
          setErrors(errors);
        }

      }
    }
    if (filesSet) {
      const feedbackFileUploaded = filesSet.find(document => document.status === 'FeedbackStored')
      if (feedbackFileUploaded) {
        setFeedbackValidated(true);
      }
      if (feedbackValidated && !feedbackFileUploaded) {
        setFeedbackValidated(false);
      }
    }
    checkForErrors();
  }, [feedbackValidated, filesSet, isFeedback, validationSchema]);

  async function handleOnClick() {
    setButtonClicked(true);
    if (!errors) {
      const incidentSaved = await saveIncident({ ...incident }, isPageBeforeSuccess)
      if (incidentSaved) {
        if (isFeedback && isPageBeforeSuccess) {
          const feedbackValidated = incidentSaved.feedbackValidated
          feedbackValidated
            ? goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback)
            : dispatch({ type: HTTP_ERROR_ENCOUNTERED, error: { message: 'Feedback not validated' } })
          return
        } else {
          goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback);
          return
        }
      }
    }
  }

  useEnterKey(handleOnClick, [errors]);

  useEffect(() => {
    if (selectedImage !== null) {
      if (filesSet === null || filesSet.length === 0) {
        setSelectedImage(null);
      } else if (filesSet !== null && !filesSet[selectedImage]) {
        setSelectedImage(null);
      }
    }
  }, [filesSet, selectedImage, setSelectedImage]);

  useEffect(() => {
    if (numOfUploads >= 1) {
      setTimeout(() => setBigImageUpload(true), 5000);
    }
    if (numOfUploads < 1 && !!bigFileUpload) {
      setBigImageUpload(false);
    }
  }, [numOfUploads, bigFileUpload])

  if (!filesSet || !insurer || !incident || !questionnaire) {
    return <Loader />;
  }

  function clickMain(event) {
    clickOutSideFaq(event);
    setSelectedImage(null);
  }

  function renderImage(image, i) {
    const selected = i === selectedImage

    const instruction = instructions[i]

    const loading = () => {
      if (numOfUploads === 0) {
        return false;
      }
      if (!image.content && loadingImg === instruction.title) {
        return true;
      }
    };

    const { cpID, content } = image;
    const instructionTitle = modifyInstruction(instructions[i], i)
    return (
      <Box
        key={cpID || i}
        display="flex"
        flexDirection="column"
        minWidth={{ xs: '200px', xl: '200px' }}
        maxWidth={{ md: isMobile ? '100%' : '250px', lg: '250px', xl: '350px' }}
        minHeight={{ xs: '500px' }}
        padding="20px 10px"
        sx={{ backgroundColor: Colors.WHITE, alignItems: 'center' }}
        width="100%"
      >
        <Stack height={'100%'} alignItems={'center'} minHeight={{ xs: '450px' }}>
          <Stack alignItems={'center'} maxWidth={{ xs: '250px', xl: '250px' }} minHeight={{ xs: '50px', xl: '80px' }}>
            <Heading level="4" className="instruction__title">
              {instructionTitle}
            </Heading>
          </Stack>
          <ImageViewerGuided
            src={`/img/examples/${instruction.example}.jpg`}
            borderThumbNail={'/img/svg/thumbnail-border-big.svg'}
            widthSize={170}
            widthSizeSmall={170}
            heightSize={100}
            heightSizeSmall={100}
            dulled={selectedImage !== null}
          />
          <Stack alignItems={'center'} maxWidth={{ lg: `${scaling.scaleUpTo4K(180)}px`, xs: `${scaling.scaleUpTo4K(180)}px` }}>
            <Paragraph>
              {instruction.explanation}
            </Paragraph>
          </Stack>
          <Stack height={'100%'} alignItems={'center'} justifyContent={'flex-end'}>
            <ImageViewerGuided
              src={content || null}
              onClick={() => { setSelectedImage(i) }}
              htmlFor={!content ? `input-file-${i}` : ''}
              selected={selected}
              clickDelete={() => deleteFile(image)}
              dulled={selectedImage !== null && selected !== i}
              loading={loading()}
              borderThumbNail={'/img/svg/thumbnail-border-big.svg'}
              widthSize={170}
              widthSizeSmall={170}
              heightSize={170}
              heightSizeSmall={170}
              inputField={<><InputFileNoStyle id={i} onChange={(event) => addUploads(event, instruction.title)} multiple={false} accepts={"image/jpeg,image/jpg,image/png"} />
                <Box flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' height={'100%'} padding={'10px'}><Box color={Colors.WHITE}><UseIcon name="icon-file" /></Box>
                </Box>
              </>}
            />
          </Stack>
        </Stack>
      </Box>

    );
  }

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible || selectedImage !== null,
    className: 'main__uploads',
    callBack: clickMain,
    $scaling: scaling,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    theme: nextButtonEnabled ? 'primary' : 'disabled',
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };

  const modifyInstruction = (instruction, i) => `Foto ${i + 1}: ${instruction.title}`

  const chooseMiddleComponent = customMiddleLogo
    ? <CompanyLogo {...middleLogo} />
    : isFeedback
      ? <Container className="empty-div" />
      : <ProgressionIndicator steps={3} current={2} />

  if (isMobile) {
    return (
      <React.Fragment>
        <FAQ
          faqListProps={faqListProps}
          faqs={faqsMobile || faqs}
          faqLink={insurer.helpcenterLink}
          customHelpLink={customHelpLink}
          helpLinkText={helpLinkText}
          faqVisible={faqVisible}
        />
        <StyledMain {...main}>
          <NavBar>
            <Container className="icon-link-container">
              {prevPage && <IconLink {...iconLink([37])} />}
              {nextPage && <IconLink {...iconLinkForward([39])} />}
            </Container>
            {chooseMiddleComponent}
            <Container className="faq-button-container">
              <IconButton {...iconButton} size="large" />
            </Container>
          </NavBar>
          <GridContainer main={true}>
            <GridColumn className="image-uploads__title mobile">
              <Legend>{legend}</Legend>
              <Heading level="1">{heading}</Heading>
              {!!feedbackCause &&
                <Container className="image_uploads-feedback-comment">
                  <Heading level="4">Opmerking van de hersteller</Heading>
                  <Paragraph className="feedback-comment">
                    {feedbackCause}
                  </Paragraph>
                </Container>}
              {showDesktopParagraphOnMobile &&
                <React.Fragment>
                  <Paragraph>
                    {firstParagraph}
                  </Paragraph>
                  {secondParagraph && <Paragraph>
                    {secondParagraph}
                  </Paragraph>}
                </React.Fragment>
              }
              {mobileParagraph && <Paragraph
                onClick={handleOnHelpClick}
                className="image-uploads__help"
              >
                {mobileParagraph}
              </Paragraph>}
              {dialogMobile && dialogMobile}
            </GridColumn>

            <GridColumn

              justify="flex-end"
              className="grid__column--main"
            >
              <ImageViewerContainer>
                {<Stepper steps={imagesDisplayed.map(renderImage)} />}
              </ImageViewerContainer>
              <Container className="uploads__validation">
                {buttonClicked && errors &&
                  errors.map(error => (
                    <StyledValidationFeedback type={'error'} key={error}>{error}</StyledValidationFeedback>
                  ))}
                {largeFileUpload && (
                  <StyledValidationFeedback>
                    Foto's moeten {maxImgSize}MB of minder zijn
                  </StyledValidationFeedback>
                )}
                {nonImageUpload && (
                  <StyledValidationFeedback>
                    Upload alleen jpg- of png-bestanden
                  </StyledValidationFeedback>
                )}
                {bigFileUpload && numOfUploads < 1 && (
                  <StyledValidationFeedback>
                    Even geduld a.u.b.<br /><br />
                    Het uploaden van grote bestanden kan even duren.
                  </StyledValidationFeedback>
                )}
              </Container>
            </GridColumn>
            <GridColumn className="grid__column--footer">
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
            </GridColumn>
          </GridContainer>
        </StyledMain>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <ConfirmationDialog
        content={`U kunt maximaal ${maxNumberOfImages} foto's uploaden`}
        onYesClick={() => setMaxNumberOfImagesDialogOpen(false)}
        open={maxNumberOfImagesDialogOpen}
        yesLabel="Bevestigen"
        title=""
        showNoButton={false}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            {prevPage && <IconLink {...iconLink([37])} />}
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container
            className="faq-button-container"
            onClick={handleOnHelpClick}
          >
            <IconButton {...iconButton} size="large" />
          </Container>
        </NavBar>
        <GridContainer main={true} direction='column'>
          <GridColumn

            justify="space-evenly"
            className="grid__column--main"
          >
            <Container className="image-upload__title">
              <Legend>{legend}</Legend>
              <Heading level="1">{heading}</Heading>
              {!!feedbackCause &&
                <Container className="image_uploads-feedback-comment">
                  <Heading level="4">Opmerking van de hersteller</Heading>
                  <Paragraph className="feedback-comment">
                    {feedbackCause}
                  </Paragraph>
                </Container>}
              <Paragraph>
                {firstParagraph}
              </Paragraph>
              {secondParagraph && <Paragraph>
                {secondParagraph}
              </Paragraph>}
            </Container>
          </GridColumn>
          <GridColumn
            justify="center"
          >
            <Container className="image-upload__uploader-container">
              <Stack display='flex' justifyContent='center' direction="row" spacing={2} useFlexGap flexWrap={{ xs: "wrap", lg: 'unset' }}>
                {imagesDisplayed.map(renderImage)}
              </Stack>
              <Container className="uploads__validation">
                {errors && buttonClicked &&
                  errors.map(error => {
                    if (typeof error === 'object') {
                      return (
                        <StyledValidationFeedback
                          type={'error'}
                          key={error.message}
                        >
                          {error.message}
                        </StyledValidationFeedback>
                      )
                    }
                    return (
                      <StyledValidationFeedback
                        type='error'
                        key={error}
                      >
                        {error}
                      </StyledValidationFeedback>
                    )
                  })}
                {largeFileUpload && (
                  <StyledValidationFeedback>
                    Foto's moeten {maxImgSize}MB of minder zijn
                  </StyledValidationFeedback>
                )}
                {nonImageUpload && (
                  <StyledValidationFeedback>
                    Upload alleen jpg- of png-bestanden
                  </StyledValidationFeedback>
                )}
                {bigFileUpload && (
                  <StyledValidationFeedback>
                    Even geduld a.u.b.<br /><br />
                    Het uploaden van grote bestanden kan even duren.
                  </StyledValidationFeedback>
                )}
              </Container>
            </Container>
            <Container className="image-upload__button-container">
              {' '}
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
              <Text className="of--enter">
                of <b>ENTER</b>
              </Text>
            </Container>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  );

}

export default GuidedImagesUploadPage;
